import { useEffect, useState } from "react";
import styled from "styled-components";
import { fetchTeam } from "../servicos/time.js";
import CardJob from "../components/CardJob/index.js";

const AppContainer = styled.div`
    min-height: calc(100vh - 8vh);
    width: 100%;
    justify-content: center;
    background-color: #EBECEE;
`

const Title = styled.h3`
    margin-top:0;
`

const RoleBlock = styled.div`
    padding: 1rem;
`

const UserBlock = styled.div`
    display:flex;
`

function Equipe() {
    const [teams, setteams] = useState([]);

    useEffect(() => {
        fetchAll();
    }, [])

    async function fetchAll() {
        const tms = await fetchTeam();
        setteams(tms);
    }

    return (
        <AppContainer>
            {Object.keys(teams).map(team => (
                <div>
                    <Title>{team}</Title>
                    {
                        Object.keys(teams[team]).map(role => (
                            <RoleBlock>
                                <h5>{role}</h5>
                                <UserBlock>
                                    {

                                        teams[team][role].map(user => {
                                            return (
                                                <CardJob
                                                    key={user.id} // Adiciona uma chave única
                                                    id={user.id}
                                                    titulo={user.name}
                                                    subtitulo={role}
                                                    imagem=""
                                                    descricao={user.email}
                                                    hour={user.team}
                                                />
                                            );
                                        })

                                    }
                                </UserBlock>
                            </RoleBlock>
                        ))
                    }
                </div>
            ))}

        </AppContainer>
    );
}

export default Equipe;
