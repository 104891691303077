import styled from "styled-components";

const AppContainer = styled.div`
    width: 100%;
    height: calc(100vh - 8vh);
`
function Tarefas() {
  return (
    <AppContainer>
      <div>Teste do laravel</div>
    </AppContainer>
  );
}

export default Tarefas;
