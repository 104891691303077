import styled from "styled-components";
import React, { useState } from "react";
import { authenticateLogin } from "../servicos/login.js";
import Logo from "../components/logo/index.js";
import { useNavigate } from "react-router-dom";

const BackgroundLogin = styled.div`
    margin: 0;
    height: 100vh;
    top: 0;
    position: absolute;
    width: 100vw;
    background-image: linear-gradient(90deg, black 40%, #101a30 80%);
    align-content: center;
    text-align: center;
`

const LoginBox = styled.div`
    background-color: rgba(255, 255, 255);
    border-radius: 2vh;
    padding: 2vh;
    width:40vw;
    text-align: center;
    align-content: center;
    display: inline-flex;
`

const ContainerInput = styled.div`
    margin: 1.5rem 0;
    display: grid;
`

const ButtonSend = styled.button`
    padding: 0.5rem 3rem;
    border-radius: .5rem;
    background-color: darkblue;
    color: white;
    font-weight: 600;
`

const LoginTitle = styled.h2`

`

const MessageBox = styled.p`
    color: red;
    font-weight: 600;
    background: darkred;
    width: fit-content;
    padding: 1rem;
    border-radius: 1rem;
    display: flex;
    margin: 1rem auto;
`

const BoxLog = styled.div`
    width: 50%;
`

const InptLogin = styled.input`
    padding: .5rem;
    border-radius: .5rem;
`


function Login() {

    const navigate = useNavigate();

    async function handleLogin(event) {
        event.preventDefault();
        try {
            const response = await authenticateLogin(email, password);
            localStorage.setItem('token', response.token);
            localStorage.setItem('refreshToken', response.refreshToken);
            setError('');
            navigate('/');
        } catch (err) {
            if (err.response && err.response.data && err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError('Erro desconhecido. Tente novamente.');
            }
        }
    }

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');

    return (
        <BackgroundLogin>
            {error && <MessageBox>{error}</MessageBox>}
            <LoginBox>
                <Logo />
                <BoxLog>
                    <LoginTitle>Login</LoginTitle>
                    <form onSubmit={handleLogin}>
                        <ContainerInput>
                            <InptLogin
                                type="email"
                                placeholder="Login"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                            />
                        </ContainerInput>
                        <ContainerInput>
                            <InptLogin
                                type="password"
                                placeholder="Senha"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                            />
                        </ContainerInput>
                        <ButtonSend type="submit">Entrar</ButtonSend>
                    </form>
                </BoxLog>
            </LoginBox>
        </BackgroundLogin>
    );
};

export default Login