import styled from "styled-components";
import { TituloHead } from "../TableSection/TableSectionComponents.js";
import { useEffect, useState } from "react";
import { getLateJobs } from "../../servicos/jobs.js";
import CardJob from "../CardJob/index.js";


const LateJobsContainer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    background-color: #EBECEE;
    flex-wrap: wrap;
`

function LateJobs() {
    const [lateJobs, setlateJobs] = useState([])

    useEffect(() => {
        fetchAll();
    }, [])

    async function fetchAll() {
        const lateJobs = await getLateJobs();
        setlateJobs(lateJobs);
    }

    return (
        <LateJobsContainer>
            <TituloHead cor="#000000">Entregas em atraso:</TituloHead>
            {lateJobs.map(lateJob => {
                const imageSrc = require(`../../images/minilogo/${lateJob.brand.toLowerCase()}.png`);

                return (
                    <CardJob
                        key={lateJob.id} // Adiciona uma chave única
                        id={lateJob.id}
                        titulo={lateJob.title}
                        subtitulo={lateJob.targeteddate}
                        imagem={imageSrc}
                        descricao={lateJob.description}
                        hour={lateJob.hours}
                        requireToolbar={"simple"}
                    />
                );
            })}
        </LateJobsContainer>
    );
}

export default LateJobs