import styled from "styled-components";

const SubmitButton = styled.button`
    float: right;
    padding: .5rem 2rem;
    border-radius: .8rem;
    background: white;
    color: blue;
    font-weight: 600;
    cursor: pointer;
`

export default SubmitButton