import axios from "axios";

const apiUrl = process.env.REACT_APP_API_URL;
const token = localStorage.getItem('token');

const convertTimeToSeconds = (hour) => {
    const [hours, minutes, seconds] = hour.split(':').map(Number);
    const secondscvt = (hours * 3600 + minutes * 60 + seconds);
    return secondscvt;
};

const convertsToTime = (sec) => {
    const totalSeconds = sec;
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;

    // Formata o tempo para sempre ter dois dígitos
    return [
        hours.toString().padStart(2, '0'),
        minutes.toString().padStart(2, '0'),
        seconds.toString().padStart(2, '0')
    ].join(':');
};

async function updateTime(id, hour){
    try {
        const response = await axios.put(`${apiUrl}/updateTime/${id}/${hour}`, null, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao editar o tempo:', error);
        throw error;
    }
}

async function fetchTeam(){
    try {
        const response = await axios.get(`${apiUrl}/fetchTeam`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao editar o tempo:', error);
        throw error;
    }
}

export {
    convertTimeToSeconds,
    convertsToTime,
    updateTime,
    fetchTeam
}