import CreateButton from "../CreateButton/index.js";
import styled from "styled-components";
import Modal from "../Modal/index.js";
import { useState } from "react";
import LabelTxt from "../LabelTxt/index.js";
import InputTxt from "../InputTxt/index.js";
import SubmitButton from "../SubmitButton/index.js";
import { insertTask } from "../../servicos/jobs.js";



const PesquisaContainer = styled.section`
        background-image: linear-gradient(90deg, black 40%, #101a30 80%);
        color: #FFF;
        text-align: center;
        padding: 15px 0;
        height: 270px;
        width: 100%;
`

const Titulo = styled.h2`
        color: #FFF;
        font-size: 36px;
        text-align: center;
        width: 100%;
`

const Subtitulo = styled.h3`
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 40px;
`

const ModalContent = styled.div`
    text-align: center;
`;

const ModalTitle = styled.h2`
 color: black;
`

const FormContainer = styled.div`
    margin:1rem;
    display: ${props => props.display || "grid"};
`

const SelectPriority = styled.select`
    width: 100%;    
    padding: .5rem;
    border-radius: .5rem;    
    margin-top: .5rem;
`

const InputDate = styled.input`
    margin-left: .5rem;
    width: 100%;
    padding: .5rem;
    border-radius: .5rem;
`

function Pesquisa() {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const openModal = () => setIsModalOpen(true);
    const closeModal = () => setIsModalOpen(false);

    const [EnterDate, setEnterDate] = useState('');

    function todayDate(event) {
        const isChecked = event.target.checked;
        const todayDate = new Date().toISOString().split('T')[0];
        setEnterDate(isChecked ? todayDate : '');
    }

    async function handleForm(event) {
        event.preventDefault();
        const formData = new FormData(event.target);
        const data = Object.fromEntries(formData.entries())
        try {
            const response = await insertTask(data);
            alert(response.message);
            closeModal();
            window.location.reload();
        } catch (error) {
            alert('Erro ao inserir tarefa:', error);
            closeModal();
        }
    }

    return (
        <PesquisaContainer>
            <Titulo>Já sabe por onde começar?</Titulo>
            <Subtitulo>Comece a cadastrar suas tarefas.</Subtitulo>
            <CreateButton onClick={openModal}>Cadastrar nova tarefa</CreateButton>
            <Modal isOpen={isModalOpen} onClose={closeModal}>
                <ModalContent>
                    <ModalTitle>Cadastrar Nova Tarefa</ModalTitle>
                    <form onSubmit={handleForm}>
                        <FormContainer>
                            <LabelTxt>Marca:</LabelTxt>
                            <SelectPriority id="brand" name="brand" type="text">
                                <option value="Blue">Blue</option>
                                <option value="Asics">Asics</option>
                                <option value="Diadora">Diadora</option>
                                <option value="Skechers">Skechers</option>
                                <option value="High">High</option>
                                <option value="Jansport">Jansport</option>
                            </SelectPriority>
                        </FormContainer>
                        <FormContainer>
                            <LabelTxt>Título:</LabelTxt>
                            <InputTxt id="title" name="title" type="text"></InputTxt>
                        </FormContainer>
                        <FormContainer>
                            <LabelTxt>Descrição do problema:</LabelTxt>
                            <InputTxt id="description" name="description" type="text"></InputTxt>
                        </FormContainer>
                        <FormContainer>
                            <LabelTxt>Prioridade:</LabelTxt>
                            <SelectPriority id="priority" name="priority" type="text">
                                <option value="Imediata">Imediata</option>
                                <option value="Alta">Alta</option>
                                <option value="Média">Média</option>
                                <option value="Baixa">Baixa</option>
                                <option value="Sugestão">Sugestão</option>
                            </SelectPriority>
                        </FormContainer>
                        <FormContainer display="flex">
                            <LabelTxt>Data inicial:</LabelTxt>
                            <input type="checkbox" id="today" onChange={todayDate}></input>
                            <LabelTxt>Hoje</LabelTxt>
                            <InputDate id="datestart" name="datestart" type="date" value={EnterDate} onChange={(e) => setEnterDate(e.target.value)}></InputDate>
                        </FormContainer>
                        <FormContainer display="flex">
                            <LabelTxt>Entrega:</LabelTxt>
                            <InputDate id="dateend" name="dateend" type="date"></InputDate>
                        </FormContainer>
                        <FormContainer>
                            <LabelTxt>Anexo:</LabelTxt>
                        </FormContainer>
                        <SubmitButton type="submit">Enviar</SubmitButton>
                    </form>

                </ModalContent>
            </Modal>
        </PesquisaContainer>
    );
}

export default Pesquisa;